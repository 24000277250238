@tailwind base;
@tailwind components;
@tailwind utilities;

:hover.leaderboardItem .addFriendButton,
:hover.leaderboardItem .removeFriendButton {
  visibility: visible;
}

.leaderboardItem .addFriendButton,
.leaderboardItem .removeFriendButton {
  visibility: hidden;
}

.leaderboardItem .myself {
  visibility: hidden;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
